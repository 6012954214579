import React from "react";
import classNames from "classnames";
import i18next from "./internationalization";
import RandomMessageTimer from "./RandomMessageTimer";

const secretChars = [";", "."];
const sentence = i18next.t("sentence");
const responses = i18next.t("responses", { returnObjects: true });

class Sued extends React.Component {
  constructor() {
    super();
    this.state = {
      isShowingResult: false,
      isTyping: false,
      captureEnabled: false,
      hiddenText: "",
      text: "",
      sentencePos: 0
    };
  }

  selectHiddenElement = () => {
    this.hiddenInput.focus();
  };

  handleButton = event => {
    if (event.keyCode === 8) {
      if (this.state.captureEnabled) {
        this.setState({
          sentencePos: this.state.sentencePos - 1,
          hiddenText: this.state.hiddenText.substring(0, this.state.hiddenText.length - 1)
        });
      }
      this.setState({
        text: this.state.text.substring(0, this.state.text.length - 1)
      });
    }

    if (event.keyCode === 13) {
      if (this.state.hiddenText.trim() === "") {
        this.setState({
          hiddenText: responses[Math.floor(Math.random() * responses.length)],
          isShowingResult: true
        });
      } else {
        this.setState({
          isShowingResult: true
        });
      }
    }
  };

  handleChange = event => {
    const value = event.target.value;
    const isSecretKey = secretChars.indexOf(value) > -1;

    if (this.state.isShowingResult === true) {
      return;
    }

    if (isSecretKey) {
      this.setState({
        captureEnabled: !this.state.captureEnabled
      });
    }

    if (isSecretKey === false && this.state.captureEnabled) {
      this.setState({
        hiddenText: this.state.hiddenText + value,
        sentencePos: this.state.sentencePos + 1,
        text: this.state.text + this.GetNextCharSentence()
      });
    }

    if (isSecretKey === false && this.state.captureEnabled === false) {
      this.setState({
        text: this.state.text + value
      });
    }
  };

  Reset = () => {
    this.setState({
      isShowingResult: false,
      isTyping: false,
      captureEnabled: false,
      hiddenText: "",
      text: "",
      sentencePos: 0
    });
  };

  GetNextCharSentence() {
    if (this.state.sentencePos !== sentence.length) {
      const myChar = sentence.substring(this.state.sentencePos, this.state.sentencePos + 1);
      return myChar;
    }
    return "";
  }

  render() {
    return (
      <React.Fragment>
        <h1 style={{ display: "none" }}>SUED</h1>
        <div className="row">
          <div id="sued">
            <img src="/images/penta.png" className="penta animated infinite pulse slower" alt="Sued Pentagrama" />
            <img src="/images/shadow.png" className="part shadow animated fadeIn slower" alt="Sued Sombra" />
            <img src="/images/light.png" className="part light animated infinite flash slower" alt="Sued Luz" />
            <img src="/images/arm.png" className="part arm animated infinite slower" alt="Sued Braço" />
            <img src="/images/body.png" className="part body animated infinite slower" alt="Sued Corpo" />
            <img src="/images/head.png" className="part head animated infinite slower" alt="Sued Cabeça" />
            <img src="/images/cloud.png" className="part cloud animated infinite slower" alt="Sued Nuvem" />
            <span
              className={classNames("ballon-input", {
                "ballon-input-alt": this.state.isShowingResult
              })}
            >
              {this.state.isShowingResult ? this.state.hiddenText : <RandomMessageTimer />}
            </span>
          </div>
        </div>
        <div className="row question-area no-gutters">
          <div className="col no-gutters">
            {this.state.isShowingResult ? (
              <div className="reset-button">
                <span className="paper">
                  <input type="button" value={i18next.t("againButton")} onClick={this.Reset} />
                </span>
              </div>
            ) : (
              <div className="paper-container paper-after" onClick={this.selectHiddenElement}>
                <div className="paper-container paper-before">
                  <div className="paper">
                    <input
                      ref={input => {
                        this.hiddenInput = input;
                      }}
                      onFocus={() => {
                        this.setState({ isTyping: true });
                      }}
                      onBlur={() => {
                        this.setState({ isTyping: false });
                      }}
                      onChange={this.handleChange}
                      onKeyDown={this.handleButton}
                      type="password"
                      spellCheck="false"
                      value=""
                      autoCapitalize="off"
                      autoCorrect="off"
                      autoComplete="nofill"
                      style={{ position: "absolute", opacity: 0, width: "1px" }}
                    />
                    {this.state.text === "" && this.state.isTyping === false ? <span className="typeArea">{i18next.t("typeInstructions")}</span> : this.state.text}
                    <span className="cursor typeArea">{this.state.isTyping ? "|" : <span>&nbsp;</span>}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Sued;
