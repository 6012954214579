import React from "react";
import i18next from "./internationalization";

const preSentenses = i18next.t("preSentences", { returnObjects: true });

export default class RandomMessageTimer extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      message: this.getRandomMessage()
    };
  }

  componentDidMount() {
    const intervalId = setInterval(() => {
      this.timer();
    }, 6000);
    this.setState({ intervalId: intervalId });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  getRandomMessage() {
    return preSentenses[Math.floor(Math.random() * preSentenses.length)];
  }

  timer() {
    this.setState({ message: this.getRandomMessage() });
  }

  render() {
    return <span>{this.state.message}</span>;
  }
}
